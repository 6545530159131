<template>
  <DetailsDrawerSection title="Änderung">
    <template v-slot:quick-actions>
      <template v-if="canEdit">
        <v-btn
          icon
          small
          :disabled="loading"
          :loading="deletingReplacement"
          @click="deleteReplacement()"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          class="ml-3"
          :disabled="loading"
          @click="$emit('edit-replacement')"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </template>

    <v-row
      v-if="diffSchoolSubject"
      dense
    >
      <v-col class="d-flex">
        <div class="flex-grow-1 text-right">
          <v-chip
            small
            :color="schoolSubjectColor(oldSchoolSubject)"
          >
            <v-icon
              left
              small
            >mdi-school-outline</v-icon>
            {{ schoolSubjectText(oldSchoolSubject) }}
          </v-chip>
        </div>
        <v-icon small>mdi-arrow-right</v-icon>
        <div class="flex-grow-1">
          <v-chip
            small
            :color="schoolSubjectColor(newSchoolSubject)"
          >
            <v-icon
              left
              small
            >mdi-school</v-icon>
            {{ schoolSubjectText(newSchoolSubject) }}
          </v-chip>
        </div>
      </v-col>
    </v-row>

    <v-row
      v-if="diffTeachers"
      dense
    >
      <v-col class="d-flex">
        <div class="flex-grow-1 d-flex flex-column align-end justify-center">
          <v-chip
            v-for="teacher in oldTeachers || []"
            :key="'old-' + teacher"
            small
          >
            <v-icon
              left
              small
            >mdi-account-outline</v-icon>
            {{ teacherTextByIri(teacher) }}
          </v-chip>
        </div>
        <v-icon small>mdi-arrow-right</v-icon>
        <div class="flex-grow-1 d-flex flex-column align-start justify-center">
          <v-chip
            v-for="teacher in newTeachers || []"
            :key="teacher"
            small
          >
            <v-icon
              left
              small
            >mdi-account</v-icon>
            {{ teacherTextByIri(teacher) }}
          </v-chip>
        </div>
      </v-col>
    </v-row>

    <v-row
      v-if="diffClassrooms"
      dense
    >
      <v-col class="d-flex">
        <div class="flex-grow-1 d-flex flex-column align-end justify-center">
          <v-chip
            v-for="classroom in oldClassrooms || []"
            :key="'old-' + classroom"
            small
          >
            <v-icon
              left
              small
            >mdi-map-marker-outline</v-icon>
            {{ classroomTextByIri(classroom) }}
          </v-chip>
        </div>
        <v-icon small>mdi-arrow-right</v-icon>
        <div class="flex-grow-1 d-flex flex-column align-start justify-center">
          <v-chip
            v-for="classroom in newClassrooms || []"
            :key="classroom"
            small
          >
            <v-icon
              left
              small
            >mdi-map-marker</v-icon>
            {{ classroomTextByIri(classroom) }}
          </v-chip>
        </div>
      </v-col>
    </v-row>
    <LessonTextField
      label="Vertretungshinweise"
      :value="comment"
    />

  </DetailsDrawerSection>
</template>


<script>
import { mapGetters } from 'vuex'
import DetailsDrawerSection from '@/pages/schedule.page/details-drawer/details-drawer-section'
import LessonTextField from '@/components/lesson-text-field'

export default {
  name: 'ReplacementSection',
  components: {
    DetailsDrawerSection,
    LessonTextField
  },
  props: {
    loading: Boolean,
    canEdit: Boolean,
    actualLesson: Object
  },
  data: () => ({
    deletingReplacement: false
  }),
  computed: {
    ...mapGetters('common', [
      'classroomByIri',
      'teacherByIri',
      'schoolSubjectByIri'
    ]),
    diffSchoolSubject() {
      const { scheduledLesson, replacement } = this.actualLesson
      return scheduledLesson?.schoolSubject !== replacement?.schoolSubject
    },
    oldSchoolSubject() {
      const { scheduledLesson } = this.actualLesson
      return this.schoolSubjectByIri(scheduledLesson?.schoolSubject)
    },
    newSchoolSubject() {
      const { replacement } = this.actualLesson
      return this.schoolSubjectByIri(replacement?.schoolSubject)
    },
    diffTeachers() {
      const { oldTeachers, newTeachers } = this
      return !Array.isArray(oldTeachers)
            || !Array.isArray(newTeachers)
            || !oldTeachers.every(teacher => newTeachers.includes(teacher))
            || !newTeachers.every(teacher => oldTeachers.includes(teacher))
    },
    oldTeachers() {
      return this.actualLesson.scheduledLesson?.teachers
    },
    newTeachers() {
      return this.actualLesson.replacement?.teachers
    },
    diffClassrooms() {
      const { oldClassrooms, newClassrooms } = this
      return !Array.isArray(oldClassrooms)
            || !Array.isArray(newClassrooms)
            || !oldClassrooms.every(classroom => newClassrooms.includes(classroom))
            || !newClassrooms.every(classroom => oldClassrooms.includes(classroom))
    },
    oldClassrooms() {
      return this.actualLesson.scheduledLesson?.classrooms
    },
    newClassrooms() {
      return this.actualLesson.replacement?.classrooms
    },
    comment() {
      return this.actualLesson.replacement?.comment
    }
  },
  methods: {
    schoolSubjectColor(schoolSubject) {
      return schoolSubject?.color
    },
    schoolSubjectText(schoolSubject) {
      return schoolSubject?.name || '?'
    },
    teacherTextByIri(teacherIri) {
      return this.teacherByIri(teacherIri)?.lastName
    },
    classroomTextByIri(classroomIri) {
      return this.classroomByIri(classroomIri)?.code
    },
    deleteReplacement() {
      const done = () => this.deletingReplacement = false
      this.deletingReplacement = true
      this.$emit('delete-replacement',  {done, error: done})
    }
  }
}
</script>


<style lang="scss" scoped>
.v-chip {
  margin: 1px;
}
</style>
