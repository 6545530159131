<template>
  <div class="schedule-week">
    <div class="schedule-week-row">
      <span class="schedule-week-cell">St.</span>
      <span
        v-for="day in days"
        :key="day.dateString"
        class="schedule-week-cell day-title"
        :class="{'is-today': day.isToday}"
      >
        <span>{{ day.date | formatDate }}</span>
        <span
          v-if="!day.section"
          class="grey--text"
        >Ferien</span>
        <span
          v-else-if="(day.dayPlan || {}).isHoliday"
          class="grey--text"
        >
          <v-icon small>{{ day.dayPlan.icon }}</v-icon>
          {{ day.dayPlan.name }}
        </span>
      </span>
    </div>
    <div
      v-for="hour in hours"
      :key="hour.hourNumber"
      class="schedule-week-row"
    >
      <span class="schedule-week-cell">{{ hour.hourNumber }}</span>
      <span
        v-for="day in hour.days"
        :key="day.dateString"
        class="schedule-week-cell"
      >
        <DayPlanSlotLesson
          v-if="day.dayPlanSlot"
          :actual-lessons="day.dayPlanSlot.actualLessons"
          :selected="isItemSelected(day)"
          :mini="$vuetify.breakpoint.xsOnly"
          @click="$emit('day-plan-slot-click', {day, dayPlanSlot: day.dayPlanSlot})"
        />
      </span>
    </div>
  </div>
</template>


<script>
import { dateRangeToArray, dateToString, formatDate, isWeekend } from '@/helpers/datetime'
import DayPlanSlotLesson from '@/components/schedule-week/day-plan-slot-lesson'

export default {
  name: 'ScheduleWeek',
  components: {
    DayPlanSlotLesson
  },
  filters: {
    formatDate: value => formatDate(value, {day: 'numeric', weekday: 'short'})
  },
  props: {
    week: Object,
    scheduleDays: Array,
    selectedItemData: Object
  },
  computed: {
    days() {
      const { week } = this
      if (!week) return []
      return dateRangeToArray(week.startsOn, week.endsOn).map(date => {
        const dateString = dateToString(date)
        const scheduleDay = this.scheduleDays.find(d => d.dateString === dateString) || {}
        return {
          date,
          dateString,
          section: null,
          dayPlanSlots: [],
          ...scheduleDay,
          selected: dateString === this.selectedItemData?.day?.dateString
        }
      })
      .filter(d => d.dayPlanSlots.length > 0 || !isWeekend(d.date))
    },
    hours() {
      const hourNumbers = []
      for (const day of this.days) {
        const dayPlanSlots = day.dayPlanSlots.filter(s => 'lesson' === s.type)
        hourNumbers.push(...dayPlanSlots.map(s => s.hourNumber))
      }
      return [...new Set(hourNumbers)].sort().map(hourNumber => {
        const days = this.days.map(day => {
          const dayPlanSlot = day.dayPlanSlots.find(s => s.hourNumber === hourNumber)
          return {...day, dayPlanSlot}
        })
        return {hourNumber, days}
      })
    },
    isItemSelected() {
      return day => {
        const { selected, dayPlanSlot } = day
        return selected && dayPlanSlot &&  dayPlanSlot['@id'] === this.selectedItemData?.dayPlanSlot?.['@id']
      }
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

$border: 1px solid map-get($material-theme, 'dividers');

.schedule-week {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 11px;

  .day-title {
    display: flex;
    flex-direction: column;

    & > :not(:first-child) {
      font-size: 10px;
      text-align: center;
      word-break: break-all;
    }

    &.is-today {
      color: $color-primary;
    }
  }

  .schedule-week-row {
    display: flex;

    &:first-child {
      font-weight: bold;
    }

    &:not(:first-child) {
      border-top: $border;
    }

    .schedule-week-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px;

      &:first-child {
        font-weight: bold;
        text-align: center;
        width: 24px;
        align-self: center;
      }

      &:not(:first-child) {
        flex: 1;
        border-left: $border;
      }
    }
  }
}
</style>
