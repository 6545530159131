<template>
  <div class="day-plan-slot-pause">
    <div class="day-plan-slot-pause-title">
      {{ duration }} Min
    </div>
    <div class="day-plan-slot-pause-content">
      {{ dayPlanSlot.name }}
    </div>
  </div>
</template>


<script>
import { timeStringToMinutes } from '@/helpers/datetime'

export default {
  name: 'DayPlanSlotPause',
  props: {
    dayPlanSlot: {
      type: Object,
      required: true
    }
  },
  computed: {
    duration() {
      const { startsAt, endsAt } = this.dayPlanSlot
      return timeStringToMinutes(endsAt) - timeStringToMinutes(startsAt)
    }
  }
}
</script>


<style lang="scss" scoped>
.day-plan-slot-pause {
  display: flex;
  align-items: center;
  cursor: default;
}

.day-plan-slot-pause-title {
  text-align: right;
  min-width: 4em;
}

.day-plan-slot-pause-content {
  flex: 1;
  color: #5b6ab9;
  font-style: italic;
  padding-left: 1rem;
}

@media screen and (max-width: 599px) {
  .day-plan-slot-pause {
    font-size: 0.5625rem;
    line-height: 6px;
    flex: 1 0 8px;
    max-height: 20px;
  }
}

@media screen and (min-width: 600px) {
  .day-plan-slot-pause {
    font-size: 0.75rem;
    height: 20px;
  }
}
</style>
