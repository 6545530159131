<template>
  <div class="d-flex flex-column fill-height">

    <ToolbarTop
      :loading="loading || loadingActualLessons"
      :mini="mini"
      :show-edit-week-comments-btn="showEditWeekCommentsBtn"
      @show:edit-week-comments-dialog="editWeekCommentsDialogData = $event"
      @input:context-object="setContextObject"
      @input:date-interval="setDateInterval"
    />

    <component
      :is="contentComponent"
      :schedule-days="scheduleDays"
      :selected-item-data="selectedItemData"
      :loading="loadingActualLessons"
      class="flex-grow-1"
      @load-actual-lessons="loadActualLessons"
      @change:selected-item-data="selectedItemData = $event"
    />

    <v-spacer></v-spacer>

    <ToolbarBottom
      v-if="mini"
      :loading="loading || loadingActualLessons"
      @input:date-interval="setDateInterval"
    />

    <v-overlay
      v-if="!mini"
      :value="loadingDetailsDrawer"
      absolute
      opacity="0"
    ></v-overlay>

    <AppLoader v-show="loading" />

    <DetailsDrawer
      :value="selectedItemData"
      :mini="mini"
      @change:loading="loadingDetailsDrawer = $event"
      @updated:actual-lesson="refreshActualLesson"
      @show:edit-week-comments-dialog="editWeekCommentsDialogData = $event"
      @close="selectedItemData = null"
    />

    <EditWeekCommentsDialog
      v-if="editWeekCommentsDialogData"
      :value="editWeekCommentsDialogData"
      :mini="mini"
      @created:week-comment="onWeekCommentsChange"
      @deleted:week-comment="onWeekCommentsChange"
      @close="closeEditWeekCommentsDialog()"
    />

  </div>
</template>


<script>
import { mapState, mapGetters } from 'vuex'
import { api } from '@/api'
import AppLoader from '@/components/app-loader'
import ToolbarTop from '@/pages/schedule.page/toolbar-top'
import ToolbarBottom from '@/pages/schedule.page/toolbar-bottom'
import DetailsDrawer from '@/pages/schedule.page/details-drawer'
import ViewDay from '@/pages/schedule.page/view-day'
import ViewDayMobile from '@/pages/schedule.page/view-day-mobile'
import ViewWeek from '@/pages/schedule.page/view-week'
import ViewWeekMobile from '@/pages/schedule.page/view-week-mobile'
import ViewMonth from '@/pages/schedule.page/view-month'
import ViewMonthMobile from '@/pages/schedule.page/view-month-mobile'
import EditWeekCommentsDialog from '@/pages/schedule.page/edit-week-comments-dialog'
import ActualLesson from '@/entities/actual-lesson'

export default {
  name: 'SchedulePage',
  components: {
    AppLoader,
    ToolbarTop,
    ToolbarBottom,
    DetailsDrawer,
    ViewDay,
    ViewDayMobile,
    ViewWeek,
    ViewWeekMobile,
    ViewMonth,
    ViewMonthMobile,
    EditWeekCommentsDialog
  },
  data: () => ({
    actualLessons: [],
    selectedItemData: null,
    loadingActualLessons: null,
    loadingDetailsDrawer: null,
    editWeekCommentsDialogData: null
  }),
  computed: {
    ...mapState(['contextObject']),
    ...mapGetters('common', ['loading']),
    ...mapState('schoolCalendar', [
      'academicYear',
      'section'
    ]),
    ...mapGetters('schoolCalendar', [
      'activeDay',
      'activeWeek',
      'activeMonth',
      'daysBySection',
      'weeksBySection'
    ]),
    mini() {
      return this.$vuetify.breakpoint.xsOnly
    },
    showEditWeekCommentsBtn() {
      const contextType = this.contextObject?.['@type']
      return (!!this.activeDay || !!this.activeWeek) && ['SchoolClass', 'Course'].includes(contextType)
    },
    contentComponent() {
      const { mini } = this
      if (this.activeDay) return mini ? ViewDayMobile : ViewDay
      if (this.activeWeek) return mini ? ViewWeekMobile : ViewWeek
      if (this.activeMonth) return mini ? ViewMonthMobile : ViewMonth
      return null
    },
    scheduleDays() {
      const { section } = this
      if (!section) return []
      return this.daysBySection(section).map(day => {
        const { dateString } = day
        const dayPlanSlots = (day.dayPlanSlots || []).map(dayPlanSlot => {
          const actualLessons = this.actualLessons.filter(({ date, scheduledLesson }) =>
            scheduledLesson.dayPlanSlot === dayPlanSlot['@id'] && date === dateString
          )
          return {...dayPlanSlot, actualLessons}
        })
        return {...day, dayPlanSlots}
      })
    },
    showWelcomePage() {
      const state = this.$store.state.common
      return state.academicYears.length == 0
        || state.sections.length == 0
        || state.teachers.length == 0
        || state.classrooms.length == 0
        || state.schoolSubjects.length == 0
    }
  },
  async created() {
    const { dispatch } = this.$store
    await this.loadCommonData()
    if (this.showWelcomePage) {
      this.$router.push({name: 'welcome'})
      return
    }
    await dispatch('schoolCalendar/setNearestAcademicYear')
    await dispatch('schoolCalendar/setNearestSection')
    await Promise.all([
      dispatch('common/fetchSchoolClassesByAcademicYear', this.academicYear),
      dispatch('common/fetchDayPlansBySection', this.section),
      dispatch('common/fetchCoursesBySection', this.section)
    ])
    await dispatch('schoolCalendar/setNearestSchoolDay')
    dispatch('resetContextObject')
    this.loadActualLessons()
  },
  methods: {
    loadCommonData() {
      return this.$store.dispatch('common/fetchCollectionOnce', [
        'academicYears',
        'classrooms',
        'schoolSubjects',
        'sections',
        'teachers'
      ])
    },
    async loadActualLessons(options) {
      const { section, contextObject } = this
      const { reset = false } = options || {}
      let { startDate, endDate } = options || {}
      if (!(section && startDate && endDate && contextObject)) return
      if (reset) this.actualLessons = []
      if (startDate < section.startsOn) startDate = section.startsOn
      if (section.endsOn < endDate) endDate = section.endsOn
      this.loadingActualLessons = true
      try {
        const url = `${contextObject['@id']}/actual_lessons`
        const groups = ['actual_lesson:brief', 'actual_lesson:include_scheduled_lesson']
        const params = {startDate, endDate, groups}
        const response = await api.get(url, {params})
        const actualLessons = response.data.map(item => new ActualLesson(item))
        if (reset) {
          this.actualLessons = actualLessons
        } else {
          const newIds = actualLessons.map(l => l['@id'])
          this.actualLessons = [
            ...this.actualLessons.filter(l => !newIds.includes(l['@id'])),
            ...actualLessons
          ]
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingActualLessons = false
      }
    },
    async setDateInterval(value) {
      const { commit, dispatch } = this.$store
      const { academicYear, section } = value || {}
      const promises = []
      if (academicYear && academicYear.year !== this.academicYear?.year) {
        promises.push(
          dispatch('common/fetchSchoolClassesByAcademicYear', academicYear)
        )
      }
      if (section && section['@id'] !== this.section?.['@id']) {
        promises.push(
          dispatch('common/fetchDayPlansBySection', section),
          dispatch('common/fetchCoursesBySection', section)
        )
      }
      commit('schoolCalendar/SET_STATE', value)
      await Promise.all(promises)
      dispatch('resetContextObject')
      this.loadActualLessons()
    },
    setContextObject(contextObject) {
      this.$store.commit('SET_CONTEXT_OBJECT', contextObject)
      this.loadActualLessons()
    },
    refreshActualLesson(actualLesson) {
      const iri = actualLesson?.['@id']
      const targetActualLesson = this.actualLessons.find(l => l['@id'] === iri)
      if (!targetActualLesson) return
      for (const prop in actualLesson) {
        if (prop in targetActualLesson) {
          targetActualLesson[prop] = actualLesson[prop]
        }
      }
    },
    onWeekCommentsChange() {
      this.editWeekCommentsDialogData.isDirty = true
    },
    async closeEditWeekCommentsDialog() {
      const { isDirty } = this.editWeekCommentsDialogData
      this.editWeekCommentsDialogData = null
      if (!isDirty) return
      const { selectedItemData } = this
      if (!selectedItemData) return
      this.selectedItemData = null
      await this.$nextTick()
      this.selectedItemData = selectedItemData
    }
  }
}
</script>
