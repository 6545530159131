<template>
  <div class="lesson-text-fields">
    <div
      v-for="{ name, label } in textFields"
      :key="name"
    >
      <LessonTextField
        v-if="!filter || filter[name]"
        :label="label"
        :value="lesson[name]"
      />
    </div>
  </div>
</template>


<script>
import LessonTextField from '@/components/lesson-text-field'
import { textFields } from '@/entities/actual-lesson'

export default {
  name: 'LessonTextFields',
  components: {
    LessonTextField
  },
  props: {
    lesson: {
      type: Object,
      required: true
    },
    filter: {
      type: Object
    }
  },
  data: () => ({
    textFields
  })
}
</script>
