<template>
  <DetailsDrawerSection title="Verwaltung">
    <div class="text-center">
      <v-btn
        text
        small
        class="mx-1"
        :color="actualLesson.isCanceled ? 'primary' : 'error'"
        :disabled="loading"
        :loading="savingIsCanceled"
        @click="toggleIsCanceled()"
      >
        <v-icon left>
          {{ actualLesson.isCanceled ? 'mdi-delete-restore' : 'mdi-delete-outline' }}
        </v-icon>
        {{ actualLesson.isCanceled ? 'Absage aufheben' : 'Absagen' }}
      </v-btn>
      <v-btn
        v-if="!actualLesson.isCanceled"
        text
        small
        class="mx-1"
        color="primary"
        :disabled="loading"
        @click="$emit('show-edit-replacement-dialog')"
      >
        <v-icon left>mdi-file-replace-outline</v-icon>
        Ändern
      </v-btn>
    </div>
  </DetailsDrawerSection>
</template>


<script>
import DetailsDrawerSection from '@/pages/schedule.page/details-drawer/details-drawer-section'

export default {
  name: 'ManageSection',
  components: {
    DetailsDrawerSection
  },
  props: {
    loading: Boolean,
    actualLesson: Object
  },
  data: () => ({
    savingIsCanceled: false
  }),
  methods: {
    toggleIsCanceled() {
      const patch = {isCanceled: !this.actualLesson.isCanceled}
      const done = () => this.savingIsCanceled = false
      this.savingIsCanceled = true
      this.$emit('change:is-canceled', {patch, done, error: done})
    }
  }
}
</script>
