<template>
  <v-dialog
    persistent
    scrollable
    max-width="580"
    :value="!!value"
    :fullscreen="mini"
  >
    <v-card>
      <v-card-title class="subtitle-1 text-sm-h6 flex-nowrap">
        <v-icon class="mr-4">mdi-comment-text-outline</v-icon>
        <div class="text-truncate">
          <span>Wochenbezogene Bemerkungen</span>
        </div>
      </v-card-title>

      <v-divider/>

      <v-card-title class="justify-space-between">
        <v-chip
          small
          outlined
        >
          {{ contextObjectTypeText }}: {{ contextObjectText }}
        </v-chip>
        <div class="d-flex align-center">
          <v-btn
            icon
            small
            :disabled="loading || !prevSectionWeek"
            @click="sectionWeekIndex--"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-chip
            small
            outlined
          >
            {{ sectionWeekText }}
          </v-chip>
          <v-btn
            icon
            small
            :disabled="loading || !nextSectionWeek"
            @click="sectionWeekIndex++"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="py-0 overflow-hidden">

        <v-divider />

        <div
          v-show="loading && !weekComments.length"
          class="text-center py-4"
        >
          <v-progress-circular
            :size="54"
            :width="5"
            indeterminate
            color="grey lighten-1"
            class="mx-auto fill-width"
          ></v-progress-circular>
        </div>

        <div
          v-show="!loading && !weekComments.length"
          class="text-center py-6"
        >
          <v-alert
            class="d-inline-block ma-0"
            border="left"
            color="blue-grey lighten-4"
            dense
          >
            Es liegen noch keine Bemerkungen vor.
          </v-alert>
        </div>

        <v-list
          ref="weekCommentsList"
          class="week-comments-list"
          dense
        >
          <v-list-item
            v-for="weekComment in weekComments"
            :key="weekComment['@id']"
            class="pa-0"
            :set="teacher = teacherByIri(weekComment.teacher) || {}"
          >
            <v-list-item-avatar color="indigo">
              <v-img
                v-if="teacher.avatar"
                :src="teacher.avatar"
              />
              <span
                v-else
                v-text="teacher.code"
                class="white--text"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              {{ weekComment.text }}
            </v-list-item-content>
            <v-list-item-action v-if="canWriteWeekComment">
              <v-btn
                icon
                :disabled="loading"
                @click="deleteWeekComment(weekComment)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <template v-if="canWriteWeekComment">
          <v-divider />

          <v-list
            class="pa-0"
            dense
          >
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-text-field
                  ref="newWeekCommentText"
                  v-model.trim="newWeekCommentText"
                  :readonly="loading"
                  placeholder="Bemerkung eintragen"
                  counter="255"
                  filled
                  rounded
                  dense
                  autofocus
                  @keyup.enter="createWeekComment()"
                  @keyup.esc="newWeekCommentText = ''"
                ></v-text-field>
              </v-list-item-content>
              <v-list-item-action class="align-self-start">
                <v-btn
                  icon
                  :disabled="loading"
                  @click="newWeekCommentText ? createWeekComment() : $refs.newWeekCommentText.focus()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </template>

      </v-card-text>

      <v-card-title
        v-if="error"
        class="justify-center"
      >
        <v-alert
          dense
          outlined
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-card-title>

      <v-divider/>

      <v-card-actions class="px-6">
        <v-spacer />
        <v-btn
          :disabled="loading"
          text
          @click="$emit('close')"
        >
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters } from 'vuex'
import { api, getErrorReason } from '@/api'
import contextObjectCollections from '@/store/context-object-collections'

export default {
  name: 'EditWeekCommentsDialog',
  props: {
    value: {
      type: Object,
      required: true
    },
    mini: Boolean
  },
  data: () => ({
    loading: false,
    error: null,
    sectionWeekIndex: null,
    weekComments: [],
    newWeekCommentText: ''
  }),
  computed: {
    ...mapGetters(['canWriteWeekComment']),
    ...mapGetters('common', [
      'schoolClassByIri',
      'schoolSubjectByIri',
      'courseByIri',
      'teacherByIri'
    ]),
    ...mapGetters('schoolCalendar', ['weeksBySection']),
    contextObject() {
      const { contextObject } = this.value
      if (typeof contextObject == 'string') {
        return this.schoolClassByIri(contextObject) || this.courseByIri(contextObject)
      }
      return contextObject
    },
    contextObjectTypeText() {
      const type = this.contextObject?.['@type']
      return contextObjectCollections.find(c => type === c.type)?.title
    },
    contextObjectText() {
      const item = this.contextObject || {}
      const name = item.name || this.schoolSubjectByIri(item.schoolSubject)?.name || ''
      return `${item.code} ${name}`
    },
    sectionWeeks() {
      return this.weeksBySection(this.value.section)
    },
    sectionWeek() {
      return this.sectionWeeks[this.sectionWeekIndex] || null
    },
    sectionWeekText() {
      return this.sectionWeek?.text
    },
    prevSectionWeek() {
      return this.sectionWeeks[this.sectionWeekIndex - 1] || null
    },
    nextSectionWeek() {
      return this.sectionWeeks[this.sectionWeekIndex + 1] || null
    }
  },
  watch: {
    sectionWeekIndex() {
      this.loadWeekComments()
    }
  },
  created() {
    const { weekId } = this.value
    this.sectionWeekIndex = this.sectionWeeks.findIndex(w => w.weekId === weekId)
  },
  methods: {
    scrollToBottom() {
      const { $el } = this.$refs.weekCommentsList
      $el.lastElementChild.scrollIntoView({block: 'start', inline: 'center', behavior: 'smooth'})
    },
    async loadWeekComments() {
      const { contextObject, sectionWeek } = this
      if (!(contextObject && sectionWeek)) return
      const params = {
        pagination: false,
        contextObject: contextObject['@id'],
        calendarWeekCode: sectionWeek.weekId,
        'order[modifiedAt]': 'asc',
        'order[createdAt]': 'asc'
      }
      this.error = null
      this.loading = true
      this.weekComments = []
      try {
        const response = await api.get('/api/week_comments', {params})
        this.weekComments = response.data['hydra:member']
      } catch (e) {
        console.error(e)
        this.error = getErrorReason(e)
      } finally {
        this.loading = false
      }
    },
    async createWeekComment() {
      const { contextObject, sectionWeek, newWeekCommentText: text } = this
      if (!(contextObject && sectionWeek && text)) return
      const payload = {
        contextObject: contextObject['@id'],
        calendarWeekCode: sectionWeek.weekId,
        text: text.slice(0, 255)
      }
      this.error = null
      this.loading = true
      try {
        const response = await api.post('/api/week_comments', payload)
        const newWeekComment = response.data
        this.weekComments = [...this.weekComments, newWeekComment]
        this.$emit('created:week-comment', newWeekComment)
        this.newWeekCommentText = ''
        await this.$nextTick()
        this.scrollToBottom()
      } catch (e) {
        console.error(e)
        this.error = getErrorReason(e)
      } finally {
        this.loading = false
      }
    },
    async deleteWeekComment(weekComment) {
      this.error = null
      this.loading = true
      try {
        await api.delete(weekComment['@id'])
        this.weekComments = this.weekComments.filter(c => c !== weekComment)
        this.$emit('deleted:week-comment', weekComment)
      } catch (e) {
        console.error(e)
        this.error = getErrorReason(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.v-card__text {
  height: 50vh;
  overflow: hidden;
}

.week-comments-list {
  max-height: calc(100% - 84px);
  padding: 0;
  overflow: auto;
}
</style>
