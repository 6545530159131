<template>
  <div class="d-flex flex-column overflow-hidden">

    <div class="flex-grow-1 overflow-hidden">
      <v-carousel
        hide-delimiters
        :show-arrows="false"
        :continuous="false"
        height="100%"
        v-model="sectionWeekIndex"
      >
        <v-carousel-item
          v-for="week in weeks"
          :key="week.weekId"
          class="pa-2"
        >
          <v-card
            :loading="loading"
            class="fill-height overflow-auto"
            flat
          >
            <ScheduleWeek
              :week="activeWeek"
              :schedule-days="scheduleDays"
              :selected-item-data="selectedItemData"
              :loading="loading"
              class="pa-1"
              @day-plan-slot-click="$emit('change:selected-item-data', $event)"
            />
          </v-card>
        </v-carousel-item>
      </v-carousel>
    </div>

  </div>
</template>


<script>
import { mapState, mapGetters } from 'vuex'
import ScheduleWeek from '@/components/schedule-week'

export default {
  name: 'ViewWeekMobile',
  components: {
    ScheduleWeek
  },
  props: {
    scheduleDays: Array,
    selectedItemData: Object,
    loading: Boolean
  },
  data: () => ({
    knownWeeks: []
  }),
  computed: {
    ...mapState(['contextObject']),
    ...mapState('schoolCalendar', ['section']),
    ...mapGetters('schoolCalendar', [
      'activeWeek',
      'weeksBySection'
    ]),
    weeks() {
      return this.weeksBySection(this.section)
    },
    sectionWeekIndex: {
      get() {
        const { weekId } = this.activeWeek
        return weekId && Math.max(0, this.weeks.findIndex(w => w.weekId === weekId)) || 0
      },
      set(newIndex) {
        const { startsOn: startDate, endsOn: endDate } = this.weeks[newIndex]
        this.$store.commit('schoolCalendar/SET_DATES', {startDate, endDate})
      }
    }
  },
  watch: {
    activeWeek({ startsOn, endsOn }, prev) {
      if (startsOn === prev.startsOn && endsOn === prev.endsOn) return
      this.loadActualLessons({reset: false})
    },
    contextObject() {
      this.knownWeeks = []
      this.loadActualLessons({reset: true})
    }
  },
  created() {
    this.loadActualLessons({reset: false})
  },
  methods: {
    loadActualLessons({ reset = false }) {
      const { activeWeek } = this
      if (!activeWeek) return
      const { startsOn: startDate, endsOn: endDate } = activeWeek
      if (!reset && this.knownWeeks.includes(startDate)) return
      this.$emit('load-actual-lessons', {startDate, endDate, reset})
      this.knownWeeks = [...new Set([...this.knownWeeks, startDate])]
    }
  }
}
</script>
