<template>
  <div
    class="day"
    :class="{'is-today': day.isToday}"
  >
    <div class="text-center">
      <span class="day-title">{{ day.date.getDate() }}</span>
    </div>
    <div
      v-if="dayPlanSlots.length > 0"
      class="day-body"
    >
      <DayPlanSlotLesson
        v-for="(dayPlanSlot, index) in dayPlanSlots"
        :key="dayPlanSlot['@id']"
        :height="dayPlanSlotHeight[index]"
        :actual-lessons="dayPlanSlot.actualLessons"
        :selected="selectedDayPlanSlot === dayPlanSlot"
        :mini="mini"
        @click="$emit('day-plan-slot-click', dayPlanSlot)"
      />
    </div>
    <div
      v-else
      class="day-body-empty grey--text"
    >
      <v-icon
        color="grey"
        v-text="dayIcon"
      />
      <span
        class="font-italic text-center pa-1"
        v-text="dayTitle"
      />
    </div>
  </div>
</template>


<script>
import { timeStringToMinutes } from '@/helpers/datetime'
import DayPlanSlotLesson from '@/components/schedule-month/day-plan-slot-lesson'

export default {
  name: 'ScheduleMonthDay',
  components: {
    DayPlanSlotLesson
  },
  props: {
    day: Object,
    selectedDayPlanSlot: Object
  },
  computed: {
    mini() {
      return this.$vuetify.breakpoint.smAndDown
    },
    dayPlanSlots() {
      return (this.day?.dayPlanSlots || []).filter(s => 'lesson' === s.type)
    },
    dayPlanSlotsMinutes() {
      return this.dayPlanSlots.map(({ startsAt, endsAt }) => {
        if (!(startsAt && endsAt)) return 0
        return timeStringToMinutes(endsAt) - timeStringToMinutes(startsAt)
      })
    },
    dayPlanSlotsMinutesSum() {
      return this.dayPlanSlotsMinutes.reduce((sum, m) => sum + m, 0)
    },
    dayPlanSlotHeight() {
      return this.dayPlanSlotsMinutes.map(m => `${Math.round(10000 * m / this.dayPlanSlotsMinutesSum) / 100}%`)
    },
    dayIcon() {
      if (this.dayPlanSlots.length > 0) return null
      if (!this.day.section) return 'mdi-island'
      return this.day.dayPlan?.icon || 'mdi-calendar-question'
    },
    dayTitle() {
      if (this.dayPlanSlots.length > 0) return null
      if (!this.day.section) return 'Ferien'
      return this.day.dayPlan?.name || null
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.day {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 1px;

  .day-title {
    font-weight: bold;
  }

  &.is-today .day-title {
    color: $color-primary;
    border: 1px solid $color-primary;
    border-radius: 100px;
    padding: 0 4px;
  }

  .day-body {
     flex: 1;
  }

  .day-body-empty {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    word-break: break-all;
  }
}

@media screen and (max-width: 599px) {
  .day-body-empty {
    font-size: 10px;
  }
}

@media screen and (min-width: 600px) {
  .day-body-empty {
    font-size: 12px;
  }
}
</style>
