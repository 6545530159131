<template>
  <div class="d-flex flex-column overflow-hidden">

    <div class="flex-grow-1 overflow-hidden">
      <v-carousel
        hide-delimiters
        :show-arrows="false"
        :continuous="false"
        height="100%"
        v-model="sectionDayIndex"
      >
        <v-carousel-item
          v-for="day in days"
          :key="day.dateString"
          class="pa-2"
        >
          <v-card
            :disabled="loading || !day.dayPlanSlots.length"
            :loading="loading"
            class="fill-height overflow-auto"
            flat
          >
            <ScheduleDay
              :day="day"
              :context-object="contextObject"
              :loading="loading"
              :hour-number-selected="selectedHourNumber"
              class="pa-2"
              @day-plan-slot-click="$emit('change:selected-item-data', {day, dayPlanSlot: $event})"
            />
          </v-card>
        </v-carousel-item>
      </v-carousel>
    </div>

  </div>
</template>


<script>
import { mapState, mapGetters } from 'vuex'
import ScheduleDay from '@/components/schedule-day'

export default {
  name: 'ViewDayMobile',
  components: {
    ScheduleDay
  },
  props: {
    scheduleDays: Array,
    selectedItemData: Object,
    loading: Boolean
  },
  data: () => ({
    knownDays: []
  }),
  computed: {
    ...mapState(['contextObject']),
    ...mapState('schoolCalendar', ['section']),
    ...mapGetters('schoolCalendar', [
      'daysBySection',
      'activeDay'
    ]),
    days() {
      return this.daysBySection(this.section).map(day => {
        const { dateString } = day
        const scheduleDay = this.scheduleDays.find(d => d.dateString === dateString) || {}
        return {...day, ...scheduleDay}
      })
    },
    sectionDayIndex: {
      get() {
        const dateString = this.activeDay
        return dateString && Math.max(0, this.days.findIndex(d => d.dateString === dateString)) || 0
      },
      set(newIndex) {
        const { dateString } = this.days[newIndex]
        this.$store.commit('schoolCalendar/SET_DATES', dateString)
      }
    },
    selectedHourNumber() {
      return this.selectedItemData?.dayPlanSlot?.hourNumber || null
    }
  },
  watch: {
    activeDay(dateString, prev) {
      if (dateString === prev) return
      this.loadActualLessons({reset: false})
    },
    contextObject() {
      this.knownDays = []
      this.loadActualLessons({reset: true})
    }
  },
  created() {
    this.loadActualLessons({reset: false})
  },
  methods: {
    loadActualLessons({ reset = false }) {
      const { activeDay } = this
      if (!activeDay) return
      if (!reset && this.knownDays.includes(activeDay)) return
      this.$emit('load-actual-lessons', {startDate: activeDay, endDate: activeDay, reset})
      this.knownDays = [...new Set([...this.knownDays, activeDay])]
    }
  }
}
</script>
