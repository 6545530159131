<template>
  <v-card>
    <v-card-title>
      {{ title }}
      <v-spacer />
      <slot name="quick-actions" />
    </v-card-title>
    <v-card-text>
      <slot />
    </v-card-text>
  </v-card>
</template>


<script>
export default {
  name: 'DetailsDrawerSection',
  props: {
    title: String
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.v-sheet.v-card {
  margin: 0.5em 0.25em;
  border-top: 1px solid map-get($material-theme, 'dividers');
  border-radius: initial;
  box-shadow: none;
}

.v-card__title {
  padding: 0.5em 0.5em 0;
  font-size: 1rem;
}

.v-card__text {
  padding: 0;
}
</style>
