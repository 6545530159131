<template>
  <v-toolbar
    dense
    flat
    class="px-1"
  >
    <v-spacer />
    <v-toolbar-title class="text-uppercase subtitle-2">
      {{ day.date | dateForHumans }}
    </v-toolbar-title>
    <v-spacer />
    <v-chip outlined>
      <div class="font-weight-bold">
        {{ dayPlanSlot.hourNumber }}
      </div>
      <div class="lesson-chip-time">
        <small>{{ dayPlanSlot.startsAt | trimZeroesLeft }}</small>
        <small>{{ dayPlanSlot.endsAt | trimZeroesLeft }}</small>
      </div>
    </v-chip>
    <v-spacer />
    <v-btn
      icon
      small
      :disabled="loading"
      @click="$emit('close')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-toolbar>
</template>


<script>
import { dateForHumans } from '@/helpers/datetime'
import { trimZeroesLeft } from '@/helpers/string'

export default {
  name: 'DetailsDrawerHeader',
  filters: {
    dateForHumans,
    trimZeroesLeft
  },
  props: {
    loading: Boolean,
    day: Object,
    dayPlanSlot: Object
  }
}
</script>


<style lang="scss" scoped>
.lesson-chip-time {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  line-height: 1.12;
  margin-left: 8px;
}
</style>
