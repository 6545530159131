<template>
  <v-card class="ma-1 d-flex flex-column overflow-hidden">

    <ContextHeader />

    <v-card-text class="flex-grow-1 overflow-auto pa-2">
      <ScheduleWeek
        :week="activeWeek"
        :schedule-days="scheduleDays"
        :selected-item-data="selectedItemData"
        :loading="loading"
        @day-plan-slot-click="$emit('change:selected-item-data', $event)"
      />
    </v-card-text>

  </v-card>
</template>


<script>
import { mapState, mapGetters } from 'vuex'
import ContextHeader from '@/pages/schedule.page/context-header'
import ScheduleWeek from '@/components/schedule-week'

export default {
  name: 'ViewWeek',
  components: {
    ContextHeader,
    ScheduleWeek
  },
  props: {
    scheduleDays: Array,
    selectedItemData: Object,
    loading: Boolean
  },
  computed: {
    ...mapState(['contextObject']),
    ...mapGetters('schoolCalendar', ['activeWeek'])
  },
  watch: {
    scheduleDays() {
      this.$emit('change:selected-item-data', null)
    },
    activeWeek({ startsOn, endsOn }, prev) {
      if (startsOn === prev.startsOn && endsOn === prev.endsOn) return
      this.loadActualLessons()
    },
    contextObject() {
      this.loadActualLessons()
    }
  },
  created() {
    this.loadActualLessons()
  },
  methods: {
    loadActualLessons() {
      const { activeWeek } = this
      if (!activeWeek) return
      const { startsOn: startDate, endsOn: endDate } = activeWeek
      this.$emit('load-actual-lessons', {startDate, endDate, reset: true})
    }
  }
}
</script>
