<template>
  <v-btn
    rounded
    x-small
    text
    height="34"
    title="Anwesenheit eintragen"
    :disabled="disabled"
    v-on="$listeners"
  >

    <AbsenceChip
      v-if="studentAbsence"
      :student-absence="studentAbsence"
    />

    <v-icon
      v-else
      small
    >
      mdi-square-edit-outline
    </v-icon>

  </v-btn>
</template>


<script>
import AbsenceChip from '@/components/edit-lesson-absences-dialog/absence-chip'

export default {
  name: 'AbsenceBtn',
  components: {
    AbsenceChip
  },
  props: {
    studentAbsence: Object,
    disabled: Boolean
  }
}
</script>
