<template>
  <v-dialog
    persistent
    scrollable
    max-width="580"
    :value="!!data"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card v-if="data">
      <v-card-title class="subtitle-1 text-sm-h6 flex-nowrap">
        <v-icon class="mr-4">mdi-account-multiple-check-outline</v-icon>
        <div class="text-truncate">
          <span>An-/Abwesenheit eintragen</span>
        </div>
      </v-card-title>

      <v-divider/>

      <v-card-text>
        <v-row class="mt-1">
          <v-col
            v-if="data.students.length > 1"
            class="text-center"
          >
            <v-chip
              v-for="student in data.students"
              :key="student['@id']"
              class="student-chip-multiple"
              small
            >
              <v-avatar v-if="student.avatar">
                <v-img :src="student.avatar" />
              </v-avatar>
              {{ student.displayNameShort }}
            </v-chip>
          </v-col>
          <v-col
            v-else
            class="d-flex align-center"
          >
            <v-btn
              fab
              x-small
              elevation="1"
              :color="hasChanges ? 'primary' : 'white'"
              :title="hasChanges ? 'Speichern und vorherige' : 'Vorherige'"
              :disabled="!prevStudent || loading"
              @click="save(prevStudent)"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-spacer/>
            <v-chip :set="student = data.students[0]">
              <v-avatar
                v-if="student.avatar"
                left
              >
                <v-img :src="student.avatar"/>
              </v-avatar>
              {{ student.displayNameShort }}
            </v-chip>
            <v-spacer/>
            <v-btn
              fab
              x-small
              elevation="1"
              :color="hasChanges ? 'primary' : 'white'"
              :title="hasChanges ? 'Speichern und nächste' : 'Nächste'"
              :disabled="!nextStudent || loading"
              @click="save(nextStudent)"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center">
            <v-btn-toggle
              rounded
              class="white--text"
              v-model="isAbsent"
            >
              <v-btn
                :color="true !== isAbsent ? 'green' : 'grey'"
                :outlined="false !== isAbsent"
                :disabled="loading"
                :value="false"
              >
                <v-icon
                  color="white"
                  class="mr-1"
                >
                  mdi-emoticon-happy-outline
                </v-icon>
                Anwesend
              </v-btn>
              <v-btn
                :color="false !== isAbsent ? 'red' : 'grey'"
                :outlined="true !== isAbsent"
                :disabled="loading"
                :value="true"
              >
                Abwesend
                <v-icon
                  color="white"
                  class="ml-1"
                >
                  mdi-emoticon-sad-outline
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row class="absence-details-container">
          <v-col v-if="isAbsent === false">
            <p class="absence-lateness-message error--text text-right">
              {{ value.cameLater > 0 ? `${value.cameLater} Minuten zu spät` : '' }}
              {{ value.cameLater > 0 && value.leftEarlier > 0 ? ', ' : '' }}
              {{ value.leftEarlier > 0 ? `${value.leftEarlier} Minuten früher verlassen` : '' }}
            </p>
            <v-range-slider
              label="Verspätung"
              :color="value.cameLater > 0 || value.leftEarlier > 0 ? 'error' : 'success'"
              :max="lessonDuration"
              :disabled="loading"
              v-model="presenceRange"
            />
          </v-col>
          <v-col v-if="isAbsent === true">
            <v-select
              clearable
              label="Abwesenheit Grund"
              item-text="name"
              item-value="@id"
              :items="absenceReasons"
              :disabled="loading"
              v-model="absenceReason"
            >
              <template v-slot:selection="{ item }">
                <v-chip
                  link
                  class="absence-reason"
                  :color="item.color"
                >
                  <v-avatar left>
                    <v-icon v-text="item.icon" />
                  </v-avatar>
                  {{ item.name }}
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-chip
                  link
                  class="absence-reason"
                  :color="item.color"
                >
                  <v-avatar left>
                    <v-icon v-text="item.icon" />
                  </v-avatar>
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              outlined
              dense
              rows="3"
              label="Bemerkung"
              :counter="255"
              :rules="[length(255)]"
              :disabled="loading"
              v-model="comment"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-title
        v-if="error"
        class="justify-center"
      >
        <v-alert
          dense
          outlined
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-card-title>

      <v-divider/>

      <v-card-actions class="px-6">
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          @click="close()"
        >
          {{ hasChanges ? 'Abbrechen' : 'Schließen' }}
        </v-btn>
        <v-btn
          v-if="hasChanges"
          color="primary"
          :loading="loading"
          @click="save()"
        >
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { length } from '@/helpers/validators'
import { timeStringToMinutes } from '@/helpers/datetime'

export default {
  name: 'EditLessonAbsenceDialog',
  props: {
    data: Object,
    loading: Boolean
  },
  data: () => ({
    length,
    error: null,
    value: {
      isAbsent: null,
      cameLater: 0,
      leftEarlier: 0,
      absenceReason: null,
      comment: '',
    },
    initialValue: {}
  }),
  computed: {
    absenceReasons() {
      return this.$store.state.common.absenceReasons
    },
    prevStudent() {
      if (1 === this.data.students?.length) {
        const [ currentStudent ] = this.data.students
        const currentIndex = this.data.allStudents.findIndex(s => s === currentStudent)
        return this.data.allStudents[currentIndex - 1]
      }
      return null
    },
    nextStudent() {
      if (1 === this.data.students?.length) {
        const [ currentStudent ] = this.data.students
        const currentIndex = this.data.allStudents.findIndex(s => s === currentStudent)
        return this.data.allStudents[currentIndex + 1]
      }
      return null
    },
    lessonDuration() {
      const { startsAt, endsAt } = this.data.dayPlanSlot
      return timeStringToMinutes(endsAt) - timeStringToMinutes(startsAt)
    },
    isAbsent: {
      get() {
        return this.value.isAbsent
      },
      set(isAbsent) {
        Object.assign(this.value, {
          isAbsent,
          cameLater: 0,
          leftEarlier: 0,
          absenceReason: null
        })
      }
    },
    presenceRange: {
      get() {
        const { cameLater, leftEarlier } = this.value
        return [cameLater, this.lessonDuration - leftEarlier]
      },
      set(range) {
        Object.assign(this.value, {
          cameLater: range[0],
          leftEarlier: this.lessonDuration - range[1]
        })
      }
    },
    absenceReason: {
      get() {
        return this.$store.getters['common/absenceReasonByIri'](this.value.absenceReason) || null
      },
      set(absenceReasonIri) {
        this.value.absenceReason = absenceReasonIri || null
      }
    },
    comment: {
      get() {
        return this.value.comment || ''
      },
      set(comment) {
        this.value.comment = comment.trim()
      }
    },
    hasChanges() {
      const { value, initialValue } = this
      return Object.keys(value).some(prop => value[prop] !== initialValue[prop])
    }
  },
  watch: {
    data(newData) {
      if (newData) {
        this.init()
      }
    }
  },
  methods: {
    init() {
      this.error = null
      const lessonIri = this.data.actualLesson['@id']
      const [ student ] = this.data.students || []
      const absence = student && this.data.studentAbsences.find(absence =>
        absence.actualLesson === lessonIri && absence.student === student['@id']
      )
      const { isAbsent = null, cameLater = 0, leftEarlier = 0, absenceReason = null, comment = '' } = absence || {}
      this.initialValue = {isAbsent, cameLater, leftEarlier, absenceReason, comment}
      Object.assign(this.value, this.initialValue)
    },
    save(nextStudent) {
      this.error = null
      const done = () => {
        if (nextStudent) this.$emit('student-absence:set-student', nextStudent)
        else this.close()
      }
      if (!this.hasChanges) return done()
      this.$emit('student-absence:save', {
        students: this.data.students,
        studentAbsence: this.value,
        done,
        error: error => this.error = error
      })
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>


<style lang="scss" scoped>
.student-chip-multiple {
  margin: 1px;
  height: 26px;
  border-radius: 13px;

  .v-avatar {
    margin-left: -11px;
    margin-right: 4px;
  }
}

.absence-reason {
  width: 95%;
}

.absence-details-container {
  min-height: 120px;
}

.absence-lateness-message {
  height: 22px;
}
</style>
