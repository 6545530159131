<template>
  <div class="lesson-absences-stats">
    <v-chip
      :active="unknown > 0"
      :x-small="mini"
      color="rgb(243,245,247)"
      text-color="rgb(143,143,143)"
      class="font-weight-bold ma-1"
      :link="link"
    >
      <v-icon
        small
        left
      >
        mdi-account-question-outline
      </v-icon>
      {{ unknown }}
    </v-chip>
    <v-chip
      :active="present > 0"
      :x-small="mini"
      color="rgba(76,175,80,0.1)"
      text-color="rgb(76,175,80)"
      class="font-weight-bold ma-1"
      :link="link"
    >
      <v-icon
        small
        left
      >
        mdi-account
      </v-icon>
      {{ present }}
    </v-chip>
    <v-chip
      :active="absent > 0"
      :x-small="mini"
      color="rgba(250,14,14,0.1)"
      text-color="rgb(250,14,14)"
      class="font-weight-bold ma-1"
      :link="link"
    >
      <v-icon
        small
        left
      >
        mdi-account-outline
      </v-icon>
      {{ absent }}
    </v-chip>
  </div>
</template>


<script>
export default {
  name: 'LessonAbsencesStats',
  props: {
    mini: Boolean,
    link: Boolean,
    present: Number,
    absent: Number,
    unknown: Number
  }
}
</script>
