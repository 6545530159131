<template>
  <button
    class="day-plan-slot-lesson"
    type="button"
    :class="{selected}"
    :disabled="selected || !actualLessons.length || disabled"
    :style="{height}"
    v-on="$listeners"
  >
    <DayPlanSlotLessonItem
      v-for="actualLesson in actualLessons"
      :key="actualLesson['@id']"
      :actual-lesson="actualLesson"
      :mini="mini"
    />
  </button>
</template>


<script>
import DayPlanSlotLessonItem from '@/components/schedule-week/day-plan-slot-lesson-item'

export default {
  name: 'DayPlanSlotLesson',
  components: {
    DayPlanSlotLessonItem
  },
  props: {
    actualLessons: Array,
    height: {
      type: String,
      default: 'auto'
    },
    selected: Boolean,
    disabled: Boolean,
    mini: Boolean
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.day-plan-slot-lesson {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border: 2px solid transparent;
  border-radius: 6px;

  &.selected {
    border: 2px solid $color-primary;
  }

  & > :not(:first-child) {
    margin-top: 2px;
  }
}
</style>
