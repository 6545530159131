<template>
  <div class="d-flex flex-column overflow-hidden">

    <div class="flex-grow-1 overflow-hidden">
      <v-carousel
        hide-delimiters
        :show-arrows="false"
        :continuous="false"
        height="100%"
        v-model="sectionMonthIndex"
      >
        <v-carousel-item
          v-for="month in months"
          :key="month.monthId"
          class="pa-2"
        >
          <v-card
            :loading="loading"
            class="fill-height overflow-auto"
            flat
          >
            <ScheduleMonth
              :month="activeMonth"
              :schedule-days="scheduleDays"
              :selected-item-data="selectedItemData"
              :loading="loading"
              class="pa-1"
              @day-plan-slot-click="$emit('change:selected-item-data', $event)"
            />
          </v-card>
        </v-carousel-item>
      </v-carousel>
    </div>

  </div>
</template>


<script>
import { mapState, mapGetters } from 'vuex'
import { dateToString, dayOfWeek, findDayOfWeek } from '@/helpers/datetime'
import ScheduleMonth from '@/components/schedule-month'

export default {
  name: 'ViewMonthMobile',
  components: {
    ScheduleMonth
  },
  props: {
    scheduleDays: Array,
    selectedItemData: Object,
    loading: Boolean
  },
  data: () => ({
    knownMonths: []
  }),
  computed: {
    ...mapState(['contextObject']),
    ...mapState('schoolCalendar', ['section']),
    ...mapGetters('schoolCalendar', [
      'activeMonth',
      'monthsBySection'
    ]),
    months() {
      return this.monthsBySection(this.section)
    },
    sectionMonthIndex: {
      get() {
        const { monthId } = this.activeMonth
        return monthId && Math.max(0, this.months.findIndex(m => m.monthId === monthId)) || 0
      },
      set(newIndex) {
        const { startsOn: startDate, endsOn: endDate } = this.months[newIndex]
        this.$store.commit('schoolCalendar/SET_DATES', {startDate, endDate})
      }
    }
  },
  watch: {
    activeMonth() {
      this.loadActualLessons({reset: false})
    },
    contextObject() {
      this.knownMonths = []
      this.loadActualLessons({reset: true})
    }
  },
  created() {
    this.loadActualLessons({reset: false})
  },
  methods: {
    loadActualLessons({ reset = false }) {
      const { activeMonth } = this
      if (!activeMonth) return
      const { startsOn, endsOn } = activeMonth
      if (!reset && this.knownMonths.includes(startsOn)) return
      const startDate = dateToString(findDayOfWeek(startsOn, dayOfWeek.MONDAY))
      const endDate = dateToString(findDayOfWeek(endsOn, dayOfWeek.SUNDAY))
      this.$emit('load-actual-lessons', {startDate, endDate, reset})
      this.knownMonths = [...new Set([...this.knownMonths, startsOn])]
    }
  }
}
</script>
