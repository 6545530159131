<template>
  <div class="schedule-month">
    <div class="schedule-month-row">
      <span class="schedule-month-cell">KW</span>
      <span
        v-for="dayOfWeek in daysOfWeek"
        :key="dayOfWeek"
        v-text="dayOfWeek"
        class="schedule-month-cell text-center"
      />
    </div>
    <div
      v-for="{ weekNumber, days } in weeks"
      :key="weekNumber"
      class="schedule-month-row"
    >
      <span class="schedule-month-cell">{{ weekNumber }}</span>
      <span
        v-for="day in days"
        :key="day.dateString"
        class="schedule-month-cell"
      >
        <ScheduleMonthDay
          :day="day"
          :selected-day-plan-slot="(selectedItemData || {}).day === day ? selectedItemData.dayPlanSlot : null"
          @day-plan-slot-click="$emit('day-plan-slot-click', {day, dayPlanSlot: $event})"
        />
      </span>
    </div>
  </div>
</template>


<script>
import { mapState, mapGetters } from 'vuex'
import {
  dateFromString,
  dateRangeToArray,
  dateToString,
  dayOfWeek,
  findDayOfWeek,
  formatDate,
  getWeekOfYear,
  isWeekend
} from '@/helpers/datetime'
import ScheduleMonthDay from '@/components/schedule-month/schedule-month-day'

export default {
  name: 'ScheduleMonth',
  components: {
    ScheduleMonthDay
  },
  props: {
    month: Object,
    scheduleDays: Array,
    selectedItemData: Object
  },
  computed: {
    ...mapState('schoolCalendar', ['section']),
    ...mapGetters('schoolCalendar', ['daysBySection']),
    daysOfWeek() {
      const [ firstWeek ] = this.weeks
      return firstWeek?.days.map(({ date }) => formatDate(date, {weekday: 'short'})) || []
    },
    days() {
      const { month } = this
      if (!month) return []

      let startDate = dateFromString(month.startsOn)
      while (isWeekend(startDate)) {
        const dateString = dateToString(startDate)
        const scheduleDay = this.scheduleDays.find(d => d.dateString === dateString) || {}
        if (scheduleDay?.dayPlanSlots?.length > 0) break
        startDate.setDate(startDate.getDate() + 1)
      }
      startDate = findDayOfWeek(startDate, dayOfWeek.MONDAY)

      const endDate = findDayOfWeek(month.endsOn, dayOfWeek.SUNDAY)
      return dateRangeToArray(startDate, endDate).map(date => {
        const dateString = dateToString(date)
        const scheduleDay = this.scheduleDays.find(d => d.dateString === dateString) || {}
        return {
          date,
          dateString,
          section: null,
          dayPlanSlots: [],
          ...scheduleDay
        }
      })
      .filter(d => d.dayPlanSlots.length > 0 || !isWeekend(d.date))
    },
    weeks() {
      const weeks = new Map()
      const showSaturday = this.days.some(d => d.dayOfWeek === dayOfWeek.SATURDAY && d.dayPlanSlots?.length > 0)
      for (const day of this.days) {
        const weekNumber = getWeekOfYear(day.date).week
        const week = weeks.get(weekNumber) || {weekNumber, days: []}
        if (day.dayOfWeek !== dayOfWeek.SATURDAY || showSaturday) week.days.push(day)
        if (!weeks.has(weekNumber)) weeks.set(weekNumber, week)
      }
      return [...weeks.values()]
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

$border: 1px solid map-get($material-theme, 'dividers');

.schedule-month {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 11px;

  .schedule-month-row {
    display: flex;

    &:first-child {
      font-weight: bold;
    }

    &:not(:first-child) {
      flex: 1;
      border-top: $border;
    }

    .schedule-month-cell {
      &:first-child {
        font-weight: bold;
        text-align: center;
        width: 24px;
        align-self: center;
      }

      &:not(:first-child) {
        flex: 1;
        border-left: $border;
      }
    }
  }
}
</style>
