<template>
  <v-card-title class="component justify-space-between py-1 px-4">
    <span>{{ contextObjectTypeText }}: {{ contextObjectText }}</span>
    <span>{{ dateIntervalText }}</span>
  </v-card-title>
</template>


<script>
import { mapState, mapGetters } from 'vuex'
import contextObjectCollections from '@/store/context-object-collections'
import { formatDate } from '@/helpers/datetime'

export default {
  name: 'SchedulePageContextHeader',
  computed: {
    ...mapState(['contextObject']),
    ...mapGetters('common', ['schoolSubjectByIri']),
    ...mapState('schoolCalendar', ['section']),
    ...mapGetters('schoolCalendar', [
      'activeMonth',
      'activeWeek'
    ]),
    contextObjectTypeText() {
      const type = this.contextObject?.['@type']
      return contextObjectCollections.find(c => type === c.type)?.title
    },
    contextObjectText() {
      const item = this.contextObject || {}
      if ('Teacher' === item['@type']) return `${item.lastName}, ${item.firstName} (${item.code})`
      if ('Course' === item['@type']) {
        const schoolSubject = this.schoolSubjectByIri(item.schoolSubject)
        return `${item.code} ${schoolSubject.name}`
      }
      return `${item.code} ${item.name}`
    },
    dateIntervalText() {
      const result = [this.section.name]
      if (this.activeWeek) {
        const { startsOn, endsOn, text } = this.activeWeek
        const weekStartYear = formatDate(startsOn, {year: 'numeric'})
        const weekEndYear = formatDate(endsOn, {year: 'numeric'})
        const yearText = weekStartYear === weekEndYear ? weekStartYear : `${weekStartYear}-${weekEndYear}`
        const weekStartMonth = formatDate(startsOn, {month: 'long'})
        const weekEndMonth = formatDate(endsOn, {month: 'long'})
        const monthText = weekStartMonth === weekEndMonth ? weekStartMonth : `${weekStartMonth}-${weekEndMonth}`
        result.unshift(text, `${monthText} ${yearText}`)
      } else if (this.activeMonth) {
        result.unshift(formatDate(this.activeMonth.startsOn, {month: 'long', year: 'numeric'}))
      }
      return result.join(', ')
    }
  }
}
</script>


<style lang="scss" scoped>
.component {
  font-size: 12px;
  line-height: 1.5;
}
</style>
