<template>
  <div
    class="schedule-view-week"
    ref="days"
  >
    <v-card
      v-for="day in days"
      :key="day.dateString"
      class="day"
      :disabled="loading || !day.dayPlanSlots.length"
      :elevation="day.selected ? 12 : 2"
    >
      <ScheduleDay
        :day="day"
        :context-object="contextObject"
        :loading="loading"
        :hour-number-selected="day.selected && selectedHourNumber || null"
        @day-plan-slot-click="$emit('change:selected-item-data', {day, dayPlanSlot: $event})"
      />
    </v-card>
  </div>
</template>


<script>
import { mapState, mapGetters } from 'vuex'
import { dateRangeToArray, dateToString, isWeekend } from '@/helpers/datetime'
import ScheduleDay from '@/components/schedule-day'

export default {
  name: 'ViewDay',
  components: {
    ScheduleDay
  },
  props: {
    scheduleDays: Array,
    selectedItemData: Object,
    loading: Boolean
  },
  computed: {
    ...mapState(['contextObject']),
    ...mapState('schoolCalendar', ['section']),
    ...mapGetters('schoolCalendar', [
      'activeDay',
      'weeksBySection'
    ]),
    activeWeek() {
      const { activeDay, section } = this
      if (!(section && activeDay)) return null
      const weeks = this.weeksBySection(section)
      return weeks.find(w => w.startsOn <= activeDay && activeDay <= w.endsOn)
    },
    days() {
      const { activeDay, activeWeek } = this
      if (!activeWeek) return []
      return dateRangeToArray(activeWeek.startsOn, activeWeek.endsOn).map(date => {
        const dateString = dateToString(date)
        const scheduleDay = this.scheduleDays.find(d => d.dateString === dateString) || {}
        return {
          date,
          dateString,
          section: null,
          dayPlanSlots: [],
          ...scheduleDay,
          selected: dateString === activeDay
        }
      })
      .filter(d => d.dayPlanSlots.length > 0 || !isWeekend(d.date))
    },
    selectedHourNumber() {
      return this.selectedItemData?.dayPlanSlot?.hourNumber || null
    }
  },
  watch: {
    scheduleDays() {
      this.$emit('change:selected-item-data', null)
    },
    activeDay() {
      this.scrollToActiveDay()
      const { dateString } = this.selectedItemData?.day || {}
      if (!(dateString && dateString !== this.activeDay)) return
      this.$emit('change:selected-item-data', null)
    },
    activeWeek({ startsOn, endsOn }, prev) {
      if (startsOn === prev.startsOn && endsOn === prev.endsOn) return
      this.loadActualLessons()
    },
    contextObject() {
      this.loadActualLessons()
    },
    selectedItemData(newData) {
      const { dateString } = newData?.day || {}
      if (dateString && dateString !== this.activeDay) {
        this.$store.commit('schoolCalendar/SET_DATES', dateString)
      }
    }
  },
  created() {
    this.loadActualLessons()
  },
  mounted() {
    this.scrollToActiveDay()
  },
  methods: {
    async scrollToActiveDay() {
      await this.$nextTick()
      const index = this.days.findIndex(d => d.dateString === this.activeDay)
      if (~index) {
        const dayElt = this.$refs.days.childNodes.item(index)
        dayElt?.scrollIntoView({block: 'start', inline: 'center', behavior: 'smooth'})
      }
    },
    loadActualLessons() {
      const { activeWeek } = this
      if (!activeWeek) return
      const { startsOn: startDate, endsOn: endDate } = activeWeek
      this.$emit('load-actual-lessons', {startDate, endDate, reset: true})
    }
  }
}
</script>


<style lang="scss" scoped>
.schedule-view-week {
  height: 100%;
  display: flex;
  align-items: flex-start;
  overflow: auto;
}

.day {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 0.375em;
  min-height: 100%;

  & > * {
    flex: 1;
  }
}

@media screen and (max-width: 751px) {
  .schedule-view-week {
    padding: 0.5em;
  }

  .day {
    width: calc(100vw - 1em);

    & + & {
      margin-left: 0.5em;
    }
  }
}

@media screen and (min-width: 752px) {
  .day + .day {
    margin-left: 1em;
  }
}

@media screen and (min-width: 752px) and (max-width: 1119px) {
  .schedule-view-week {
    padding: 1em;
  }

  .day {
    width: calc(50vw - 1.5em);
  }
}

@media screen and (min-width: 1120px) {
  .schedule-view-week {
    padding: 1em;
  }
}

@media screen and (min-width: 1120px) and (max-width: 1263px) {
  .day {
    width: calc(33.3333vw - 1.33em);
  }
}

@media screen and (min-width: 1264px) {
  .schedule-view-week {
    padding: 2em;
  }

  .day {
    width: 22em;
  }
}
</style>
