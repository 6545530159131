<template>
  <DetailsDrawerSection title="Wochenbericht">

    <template v-slot:quick-actions>
      <v-btn
        v-if="canWriteWeekReport"
        icon
        small
        :disabled="loading"
        @click="showEditWeekReportDialog()"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <LessonTextField :value="text" />

    <EditWeekReportDialog
      v-if="canWriteWeekReport"
      :value="editWeekReportDialogValue"
      @close="editWeekReportDialogValue = null"
    />

  </DetailsDrawerSection>
</template>


<script>
import { api } from '@/api'
import { getWeekOfYear } from '@/helpers/datetime'
import DetailsDrawerSection from '@/pages/schedule.page/details-drawer/details-drawer-section'
import LessonTextField from '@/components/lesson-text-field'
import EditWeekReportDialog from '@/components/edit-week-report-dialog'

export default {
  name: 'WeekReportSection',
  components: {
    DetailsDrawerSection,
    LessonTextField,
    EditWeekReportDialog
  },
  props: {
    actualLesson: Object,
    loading: Boolean
  },
  data: () => ({
    weekReport: null,
    editWeekReportDialogValue: null
  }),
  computed: {
    canWriteWeekReport() {
      return this.$store.getters['canWriteWeekReport']
    },
    contextObject() {
      const { schoolClass, course } = this.actualLesson?.scheduledLesson || {}
      return schoolClass || course || null
    },
    schoolSubject() {
      return this.actualLesson?.scheduledLesson?.schoolSubject || this.course?.schoolSubject || null
    },
    calendarWeekCode() {
      const date = this.actualLesson?.date
      return date && getWeekOfYear(date).toString() || null
    },
    text() {
      return this.weekReport?.text
    }
  },
  watch: {
    actualLesson() {
      this.loadWeekReport()
    }
  },
  created() {
    this.loadWeekReport()
  },
  methods: {
    async loadWeekReport() {
      this.weekReport = null
      const { contextObject, schoolSubject, calendarWeekCode } = this
      if (!(contextObject && schoolSubject && calendarWeekCode)) return
      const params = {contextObject, schoolSubject, calendarWeekCode}
      const response = await api.get('/api/week_reports', {params})
      this.weekReport = response.data['hydra:member']?.[0] || null
    },
    showEditWeekReportDialog() {
      this.editWeekReportDialogValue = {
        contextObject: this.contextObject,
        schoolSubject: this.schoolSubject,
        calendarWeekCode: this.calendarWeekCode,
        done: () => this.loadWeekReport()
      }
    }
  }
}
</script>
