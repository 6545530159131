<template>
  <div class="lesson-chip-miltiple">

    <lesson-chip-multiple-item
      v-for="lesson in lessons"
      :key="lesson['@id']"
      :lesson="lesson"
    />

    <div
      v-if="hiddenEntriesCount > 0"
      class="hidden-entries-count"
    >
      +{{ hiddenEntriesCount }}
    </div>

  </div>
</template>


<script>
import LessonChipMultipleItem from '@/components/schedule-day/lesson-chip-multiple-item'

export default {
  name: 'LessonChipMultiple',
  components: {
    LessonChipMultipleItem
  },
  props: {
    lessons: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    visibleEntriesCount: null
  }),
  computed: {
    hiddenEntriesCount() {
      return !!this.visibleEntriesCount && this.lessons.length - this.visibleEntriesCount || 0
    }
  },
  methods: {
    countVisibleEntries() {
      const { bottom: parentBottom } = this.$el.getBoundingClientRect()
      const entries = [...this.$el.children].filter(entry => entry.classList.contains('lesson-chip-multiple-item'))
      let count = 0
      entries.forEach(entry => {
        if (entry.getBoundingClientRect().bottom < parentBottom) {
          count += 1
        }
      })
      this.visibleEntriesCount = count
    }
  },
  watch: {
    entries() {
      this.$nextTick(() => this.countVisibleEntries())
    }
  },
  mounted() {
    this.$nextTick(() => this.countVisibleEntries())
    window.addEventListener('resize', this.countVisibleEntries, false)
    window.addEventListener('orientationchange', this.countVisibleEntries, false)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.countVisibleEntries, false)
    window.removeEventListener('orientationchange', this.countVisibleEntries, false)
  }
}
</script>


<style lang="scss" scoped>
.lesson-chip-miltiple {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 1px 10px;
  line-height: 16px;
  font-weight: normal;
  background-color: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  overflow: hidden;
}

.hidden-entries-count {
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 11px;
  color:rgba(0, 0, 0, 0.3);
}
</style>
