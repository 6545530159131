<template>
  <div
    class="lesson-chip"
    :class="{canceled: lesson.isCanceled}"
    :style="{backgroundColor}"
  >

    <v-avatar
      v-if="isTeacherVisible && avatar"
      size="40"
    >
      <v-img :src="avatar" />
    </v-avatar>

    <div class="lesson-chip-body">
      <div class="lesson-chip-inner">
        <div class="font-weight-bold">
          <v-icon small>
            {{ schoolClass ? 'mdi-school' : 'mdi-school-outline' }}
          </v-icon>
          {{ title }}
        </div>
        <div
          v-if="isTeacherVisible"
          class="font-weight-regular"
          v-text="subTitle"
        />
      </div>
    </div>

    <div
      v-if="isTailVisible"
      class="lesson-chip-tail"
    >
      <div v-if="isSchoolClassVisible">
        <v-icon small>mdi-account-group</v-icon>
        {{ schoolClassCode }}
      </div>
      <div v-if="isClassroomVisible">
        <v-icon
          small
          class="classroom-icon"
        >
          mdi-map-marker
        </v-icon>
        {{ classroomCode }}
      </div>
    </div>

  </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LessonChip',
  props: {
    lesson: {
      type: Object,
      required: true
    },
    contextObject: Object
  },
  computed: {
    ...mapGetters('common', [
      'schoolClassByIri',
      'courseByIri',
      'teacherByIri',
      'schoolSubjectByIri',
      'classroomByIri'
    ]),
    schoolClass() {
      return this.schoolClassByIri(this.lesson.scheduledLesson?.schoolClass)
    },
    course() {
      return this.courseByIri(this.lesson.scheduledLesson?.course)
    },
    teachers() {
      const { teachers } = this.lesson.replacement || this.lesson.scheduledLesson
      return teachers?.map(this.teacherByIri) || []
    },
    schoolSubject() {
      const { schoolSubject } = this.lesson.replacement || this.lesson.scheduledLesson
      return this.schoolSubjectByIri(schoolSubject)
    },
    classrooms() {
      const { classrooms } = this.lesson.replacement || this.lesson.scheduledLesson
      return classrooms?.map(this.classroomByIri) || []
    },
    backgroundColor() {
      return this.schoolSubject?.color
    },
    title() {
      const { course, schoolSubject } = this
      return [course?.code, schoolSubject?.name].filter(s => s).join(': ')
    },
    subTitle() {
      const stringify = this.teachers.length === 1 ?
        teacher => `${teacher?.lastName} (${teacher?.code})`.trim() || '???' :
        teacher => teacher?.code || '???'
      return this.teachers.map(stringify).join(', ')
    },
    avatar() {
      if (this.teachers.length === 1) {
        return this.teachers[0].avatar
      }
      return null
    },
    schoolClassCode() {
      const { schoolClass } = this
      return schoolClass ? schoolClass.code : (this.lesson.scheduledLesson.schoolClass && '???')
    },
    classroomCode() {
      return this.classrooms.map(classroom => classroom?.code || '???').join(',')
    },
    isClassroomVisible() {
      return this.isVisible('classrooms')
    },
    isSchoolClassVisible() {
      return this.isVisible('schoolClass')
    },
    isTailVisible() {
      return this.isSchoolClassVisible || this.isClassroomVisible
    },
    isTeacherVisible() {
      return this.isVisible('teachers')
    }
  },
  methods: {
    isVisible(prop) {
      if ('schoolClass' === prop && this.lesson.scheduledLesson.course) return false
      const { contextObject } = this
      if (!contextObject) return true
      if ('schoolClass' === prop) return 'SchoolClass' !== contextObject['@type']
      const propValue = this.lesson.scheduledLesson[prop]
      return propValue.length > 1 || propValue[0] !== contextObject['@id']
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.lesson-chip {
  display: flex;
  align-items: center;
  padding: 2px;
  font-size: 12px;
  line-height: 1.15;
  border-radius: 100px;
  background-color: $default-school-subject-color;

  &.canceled {
    opacity: 0.5;
  }
}

.lesson-chip-body {
  position: relative;
  flex: 1;

  & > .lesson-chip-inner {
    position: absolute;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;

    & > * {
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .v-icon {
      margin-top: -2px;
    }
  }
}

.lesson-chip-tail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 54px;
  height: 40px;
  padding: 0 3px;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;

  .classroom-icon {
    margin-right: -3px;
  }
}
</style>
