<template>
  <div
    class="lesson-chip-multiple-item"
    :style="{backgroundColor}"
    :class="{canceled: lesson.isCanceled}"
    v-text="title"
  />
</template>


<script>
export default {
  name: 'LessonChipMultipleItem',
  props: {
    lesson: {
      type: Object,
      required: true
    }
  },
  computed: {
    schoolSubject() {
      const { schoolSubject } = this.lesson.replacement || this.lesson.scheduledLesson
      return this.$store.getters['common/schoolSubjectByIri'](schoolSubject)
    },
    backgroundColor() {
      return this.schoolSubject?.color
    },
    title() {
      return [
        this.$store.getters['common/courseByIri'](this.lesson.scheduledLesson?.course)?.code,
        this.schoolSubject?.name
      ].filter(s => !!s).join(': ')
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.lesson-chip-multiple-item {
  border-radius: 10px;
  white-space: nowrap;
  margin: 1px;
  padding: 1px 3px;
  font-size: 11px;
  height: 18px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: $default-school-subject-color;

  &.canceled {
    text-decoration: line-through;
    color: #666;
  }
}
</style>
