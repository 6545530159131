<template>
  <v-dialog
    persistent
    scrollable
    max-width="1024"
    :value="show"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card v-if="show">

      <v-card-title class="subtitle-1 justify-center">

        <v-icon class="mr-4">mdi-clipboard-text-outline</v-icon>

        <div class="text-sm-h6">Unterrichtsdaten</div>

        <small class="flex-grow-1 text-center text-uppercase">
          {{ actualLesson.date | dateForHumans }}
        </small>

        <DayPlanSlotLesson
          :day-plan-slot="dayPlanSlot"
          :actual-lessons="[actualLesson]"
          disabled
        />

      </v-card-title>

      <v-divider/>

      <v-card-text>
        <div
          v-for="field in textFields"
          :key="field.name"
          class="pt-4"
        >
          <v-row>
            <v-col>
              <v-textarea
                auto-grow
                outlined
                dense
                rows="4"
                counter="1000"
                :disabled="loading"
                :readonly="!canEdit"
                :rules="[length(1000)]"
                :label="field.label"
                v-model="texts[field.name]"
              />
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-title
        v-if="error"
        class="justify-center"
      >
        <v-alert
          dense
          outlined
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-card-title>

      <v-divider/>

      <v-card-actions class="px-6">
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          v-text="canEdit && hasChanges ? 'Abbrechen' : 'Schließen'"
          @click="$emit('close')"
        />
        <v-btn
          v-show="canEdit && hasChanges"
          color="primary"
          :loading="loading"
          @click="save"
        >
          Schließen
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters } from 'vuex'
import DayPlanSlotLesson from '@/components/schedule-day/day-plan-slot-lesson'
import { dateForHumans } from '@/helpers/datetime'
import { length } from '@/helpers/validators'
import { textFields } from '@/entities/actual-lesson'

export default {
  name: 'EditLessonTextsDialog',
  components: {
    DayPlanSlotLesson
  },
  filters: {
    dateForHumans
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    actualLesson: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    textFields,
    length,
    texts: {
      themeText: '',
      homeworksText: '',
      eventsText: '',
      commentText: ''
    },
    loading: false,
    error: null
  }),
  computed: {
    ...mapGetters('common', ['dayPlanSlotByIri']),
    dayPlanSlot() {
      return this.dayPlanSlotByIri(this.actualLesson?.scheduledLesson?.dayPlanSlot)
    },
    canEdit() {
      return this.$store.getters.canUpdateActualLesson(this.actualLesson)
    },
    changedTexts() {
      const { texts, actualLesson } = this
      return Object.keys(texts).filter(fieldName =>
        texts[fieldName] !== (actualLesson[fieldName] || '')
      )
    },
    hasChanges() {
      return this.show && this.changedTexts.length > 0
    }
  },
  watch: {
    show() {
      this.resetForm()
    }
  },
  methods: {
    resetForm() {
      this.error = null
      const { texts, actualLesson } = this
      const setValue = this.show ?
        (fieldName => texts[fieldName] = actualLesson[fieldName] || '') :
        (fieldName => texts[fieldName] = '')

      Object.keys(texts).forEach(setValue)
    },
    async save() {
      if (!this.canEdit || !this.hasChanges) {
        return
      }

      this.error = null
      this.loading = true
      const { texts } = this
      const entries = this.changedTexts.map(fieldName => [fieldName, texts[fieldName]])
      const patch = Object.fromEntries(entries)

      const done = () => {
        this.resetForm()
        this.loading = false
        this.$emit('close')
      }

      const error = msg => {
        this.error = msg || 'Fehler!'
        this.loading = false
      }

      this.$emit('save', {patch, done, error})
    }
  }
}
</script>


<style lang="scss" scoped>
.day-plan-slot-lesson {
  max-width: 380px;
}
</style>
