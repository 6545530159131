<template>
  <button
    class="day-plan-slot-lesson"
    type="button"
    :class="{ selected }"
    :disabled="selected || !actualLessons.length || disabled"
    v-on="$listeners"
  >

    <div
      v-if="dayPlanSlot.hourNumber"
      class="day-plan-slot-lesson-number"
      v-text="dayPlanSlot.hourNumber"
    />

    <div
      v-if="dayPlanSlot.startsAt && dayPlanSlot.endsAt"
      class="day-plan-slot-lesson-time"
    >
      {{ dayPlanSlot.startsAt | trimZeroesLeft }}<br>
      {{ dayPlanSlot.endsAt | trimZeroesLeft }}
    </div>

    <component
      v-if="actualLessons.length > 0"
      class="day-plan-slot-lesson-content"
      :is="lessonChipComponent"
      v-bind="lessonChipComponentProps"
    />

    <div
      v-if="stampText"
      class="day-plan-slot-lesson-stamp"
      v-text="stampText"
    />

  </button>
</template>


<script>
import { trimZeroesLeft } from '@/helpers/string'
import LessonChip from '@/components/schedule-day/lesson-chip'
import LessonChipMultiple from '@/components/schedule-day/lesson-chip-multiple'

export default {
  name: 'DayPlanSlotLesson',
  components: {
    LessonChip,
    LessonChipMultiple
  },
  filters: {
    trimZeroesLeft
  },
  props: {
    actualLessons: {
      type: Array,
      required: true
    },
    dayPlanSlot: {
      type: Object,
      default: () => ({})
    },
    contextObject: Object,
    selected: Boolean,
    disabled: Boolean
  },
  computed: {
    lessonChipComponent() {
      return this.actualLessons.length > 1 ? LessonChipMultiple : LessonChip
    },
    lessonChipComponentProps() {
      const props = {contextObject: this.contextObject}
      if (LessonChipMultiple === this.lessonChipComponent) {
        props.lessons = this.actualLessons
      } else {
        props.lesson = this.actualLessons[0]
      }
      return props
    },
    stampText() {
      const { actualLessons } = this
      if (actualLessons.length === 1) {
        const [ lesson ] = actualLessons
        if (lesson.isCanceled) {
          return 'Entfällt'
        }
        if (lesson.replacement) {
          return 'Änderung'
        }
      }
      if (actualLessons.length > 1 && actualLessons.some(l => !!l.replacement || !!l.isCanceled)) {
        return 'Änderung'
      }
      return null
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.day-plan-slot-lesson {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  padding: 2px 0;
  color: #111;

  &:not(.selected):not([disabled]) {
    cursor: pointer;
  }

  &.selected, &[disabled] {
    cursor: default;
  }

  &.selected {
    background: $color-selection-bg;
  }
}

.day-plan-slot-lesson-number {
  font-size: 16px;
  font-weight: bold;
  padding: 3px;
}

.day-plan-slot-lesson-time {
  text-align: right;
  font-weight: normal;
  line-height: 1.15;
  white-space: nowrap;
  padding: 3px;
}

.day-plan-slot-lesson-content {
  flex: 1;
  height: 44px;
  min-width: 180px;
}

@media screen and (max-width: 599px) {
  .day-plan-slot-lesson {
    font-size: 10px;
  }

  .day-plan-slot-lesson-time {
    width: 32px;
  }
}

@media screen and (min-width: 600px) {
  .day-plan-slot-lesson {
    font-size: 12px;
  }

  .day-plan-slot-lesson-time {
    width: 38px;
  }
}


.day-plan-slot-lesson-stamp {
  position: absolute;
  right: 10px;
  top: 4px;
  padding: 0 6px;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: rgba(0, 60, 255, 0.8);
  border: 3px solid rgba(0, 60, 255, 0.8);
  border-radius: 4px;
  transform: rotate(-8deg);
}
</style>
