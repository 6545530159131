<template>
  <div
    class="day-plan-slot-lesson-item"
    :class="{'is-canceled': actualLesson.isCanceled}"
    :style="{backgroundColor}"
    :title="tooltipText"
  >
    <span class="text-truncate">{{ title }}</span>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DayPlanSlotLessonItem',
  props: {
    actualLesson: Object,
    mini: Boolean
  },
  computed: {
    ...mapGetters('common', [
      'courseByIri',
      'schoolSubjectByIri'
    ]),
    course() {
      return this.courseByIri(this.actualLesson?.scheduledLesson?.course)
    },
    schoolSubject() {
      const { schoolSubject } = this.actualLesson?.replacement || this.actualLesson?.scheduledLesson || {}
      return this.schoolSubjectByIri(schoolSubject)
    },
    backgroundColor() {
      return this.schoolSubject?.color
    },
    tooltipText() {
      return this.mini && this.schoolSubject?.name
    },
    title() {
      const { course, schoolSubject } = this
      if (this.mini) return course?.code || schoolSubject?.code
      return [course?.code, schoolSubject?.name].filter(s => s).join(': ')
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.day-plan-slot-lesson-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 10px;
  height: 100%;
  min-height: 16px;
  border-radius: 4px;
  background: $default-school-subject-color;

  &.is-canceled {
    opacity: 0.5;

    & > * {
      text-decoration: line-through;
    }
  }

  & > * {
    position: absolute;
    width: 100%;
  }
}
</style>
