<template>
  <button
    class="day-plan-slot-lesson"
    type="button"
    :class="{selected}"
    :disabled="selected || !actualLessons.length || disabled"
    :style="{height}"
    v-on="$listeners"
  >
    <DayPlanSlotLessonItem
      v-for="actualLesson in actualLessons.slice(0, 2)"
      :key="actualLesson['@id']"
      :actual-lesson="actualLesson"
      :mini="mini"
      class="flex-grow-1"
    />
    <div
      v-if="actualLessons.length > 2"
      class="align-self-center grey--text"
    >
      +{{ actualLessons.length - 2 }}
    </div>
    <v-icon
      v-if="showAlert"
      class="lesson-alert"
    >mdi-exclamation</v-icon>
  </button>
</template>


<script>
import DayPlanSlotLessonItem from '@/components/schedule-month/day-plan-slot-lesson-item'

export default {
  name: 'DayPlanSlotLesson',
  components: {
    DayPlanSlotLessonItem
  },
  props: {
    actualLessons: Array,
    height: {
      type: String,
      default: 'auto'
    },
    selected: Boolean,
    disabled: Boolean,
    mini: Boolean
  },
  computed: {
    showAlert() {
      return this.actualLessons?.some(l => !!l.replacement || !!l.isCanceled)
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.day-plan-slot-lesson {
  display: flex;
  width: 100%;
  border: 2px solid transparent;
  border-radius: 6px;

  &.selected {
    border: 2px solid $color-primary;
  }

  & > :not(:first-child) {
    margin-left: 2px;
  }

  .lesson-alert {
    width: 5px;
    font-size: 14px;
    color: red;
  }
}
</style>
