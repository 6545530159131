<template>
  <v-alert
    class="lesson-text-field mt-6 mx-1"
    dense
    outlined
    :value="!!value"
  >
    <div class="lesson-text-field-label">{{ label }}</div>
    <pre
      class="lesson-text-field-value"
      v-text="value"
    />
  </v-alert>
</template>


<script>
export default {
  name: 'LessonTextField',
  props: ['label', 'value']
}
</script>


<style lang="scss" scoped>
.lesson-text-field {
  position: relative;
  font-size: 13px;
  border-color: rgba(0, 0, 0, 0.25) !important;
  word-break: break-all;
}

.lesson-text-field-label {
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  color: #666;
  top: -11px;
  left: 8px;
  background: #fff;
  padding: 0 3px;
}

.lesson-text-field-value {
  white-space: normal;
  word-break: normal;
  text-align: justify;
  font-family: inherit;
}
</style>
