<template>
  <DetailsDrawerSection title="Unterrichtsdaten">
    <template v-slot:quick-actions>
      <v-btn
        v-if="canUpdateActualLesson(actualLesson)"
        icon
        small
        :disabled="loading"
        @click="showEditDialog = true"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <LessonTextFields
      v-if="hasTexts"
      :lesson="actualLesson"
    />

    <EditLessonTextsDialog
      v-if="canUpdateActualLesson(actualLesson)"
      :show="showEditDialog"
      :actual-lesson="actualLesson"
      @save="$emit('change:texts', $event)"
      @close="showEditDialog = false"
    />

  </DetailsDrawerSection>
</template>


<script>
import DetailsDrawerSection from '@/pages/schedule.page/details-drawer/details-drawer-section'
import LessonTextFields from '@/components/lesson-text-fields'
import EditLessonTextsDialog from '@/components/edit-lesson-texts-dialog'

export default {
  name: 'TextsSection',
  components: {
    DetailsDrawerSection,
    LessonTextFields,
    EditLessonTextsDialog
  },
  props: {
    actualLesson: Object,
    loading: Boolean
  },
  data: () => ({
    showEditDialog: false
  }),
  computed: {
    canUpdateActualLesson() {
      return this.$store.getters['canUpdateActualLesson']
    },
    hasTexts() {
      return this.actualLesson?.hasTexts()
    }
  }
}
</script>
