export const textFields = [
  {name: 'themeText', label: 'Thema'},
  {name: 'homeworksText', label: 'Hausaufgaben'},
  {name: 'eventsText', label: 'Ereignisse'},
  {name: 'commentText', label: 'Bemerkung'}
]


export default class ActualLesson {
  constructor(rawData) {
    Object.assign(this, rawData)
  }

  hasTexts() {
    return !!textFields.find(({ name }) => !!this[name])
  }

  hasTeacher(teacher) {
    return !!teacher && !!(
      this.scheduledLesson?.teachers?.includes(teacher) ||
      this.replacement?.teachers?.includes(teacher)
    )
  }
}
