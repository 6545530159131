<template>
  <AppBar>

    <v-btn
      v-if="showEditWeekCommentsBtn"
      x-small
      depressed
      height="32"
      color="primary"
      class="mx-2"
      title="Wochenbezogene Bemerkungen"
      @click="showEditWeekCommentsDialog"
    >
      <v-icon small>mdi-comment-text-outline</v-icon>
    </v-btn>

    <ContextObjectSelect
      v-model="contextObject"
      :loading="loading"
    />

    <DateRangePicker
      v-if="!mini"
      v-model="dateInterval"
      :loading="loading"
      class="ml-4"
    />

  </AppBar>
</template>


<script>
import AppBar from '@/components/app-bar'
import ContextObjectSelect from '@/components/context-object-select'
import DateRangePicker from '@/components/date-range-picker'
import { getWeekOfYear } from '@/helpers/datetime'

export default {
  name: 'ScheduleToolbarTop',
  components: {
    AppBar,
    ContextObjectSelect,
    DateRangePicker
  },
  props: {
    loading: Boolean,
    mini: Boolean,
    showEditWeekCommentsBtn: Boolean
  },
  computed: {
    contextObject: {
      get() {
        return this.$store.state.contextObject
      },
      set(contextObject) {
        this.$emit('input:context-object', contextObject)
      }
    },
    dateInterval: {
      get() {
        const { academicYear, section, startDate, endDate } = this.$store.state.schoolCalendar
        return {academicYear, section, startDate, endDate}
      },
      set(value) {
        this.$emit('input:date-interval', value)
      }
    }
  },
  methods: {
    showEditWeekCommentsDialog() {
      const { contextObject } = this
      const { section, startDate } = this.dateInterval
      const weekId = getWeekOfYear(startDate).toString()
      this.$emit('show:edit-week-comments-dialog', {contextObject, section, weekId})
    }
  }
}
</script>
