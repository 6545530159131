<template>
  <div
    class="schedule-day"
    :class="{'is-today': day.isToday}"
  >

    <div class="schedule-day-title">
      {{ day.date | dateForHumans }}
    </div>

    <div
      v-if="day.dayPlanSlots.length > 0"
      class="day-plan"
    >
      <component
        class="day-plan-slot"
        v-for="dayPlanSlot in day.dayPlanSlots"
        :key="dayPlanSlot['@id']"
        :is="dayPlanSlotComponent(dayPlanSlot)"
        v-bind="dayPlanSlotComponentProps(dayPlanSlot)"
        v-on="dayPlanSlotComponentListeners(dayPlanSlot)"
      />
    </div>

    <div
      v-else-if="!loading"
      class="day-plan-empty"
    >
      <v-icon
        color="grey"
        size="64"
        v-text="dayIcon"
      />
      <span
        class="text-h6 font-italic text-center pa-3"
        v-text="dayTitle"
      />
    </div>

  </div>
</template>


<script>
import DayPlanSlotLesson from '@/components/schedule-day/day-plan-slot-lesson'
import DayPlanSlotPause from '@/components/schedule-day/day-plan-slot-pause'
import { dateForHumans } from '@/helpers/datetime'

export default {
  name: 'ScheduleDay',
  components: {
    DayPlanSlotLesson,
    DayPlanSlotPause
  },
  filters: {
    dateForHumans
  },
  props: {
    day: {
      type: Object,
      required: true
    },
    contextObject: Object,
    loading: Boolean,
    disabled: Boolean,
    hourNumberSelected: Number
  },
  computed: {
    dayIcon() {
      if (this.day.dayPlanSlots.length > 0) return null
      if (!this.day.section) return 'mdi-island'
      return this.day.dayPlan?.icon || 'mdi-calendar-question'
    },
    dayTitle() {
      if (this.day.dayPlanSlots.length > 0) return null
      if (!this.day.section) return 'Ferien'
      return this.day.dayPlan?.name || null
    }
  },
  methods: {
    dayPlanSlotComponent({ type }) {
      return `day-plan-slot-${type}`
    },
    dayPlanSlotComponentProps(dayPlanSlot) {
      const props = {dayPlanSlot}
      if ('lesson' === dayPlanSlot.type) {
        const { contextObject, hourNumberSelected } = this
        Object.assign(props, {
          contextObject,
          actualLessons: dayPlanSlot.actualLessons,
          selected: dayPlanSlot.hourNumber === hourNumberSelected
        })
      }
      return props
    },
    dayPlanSlotComponentListeners(dayPlanSlot) {
      if ('lesson' === dayPlanSlot.type) {
        return {
          click: () => this.$emit('day-plan-slot-click', dayPlanSlot)
        }
      }
      return null
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.schedule-day {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.schedule-day-title {
  text-align: center;
  padding-bottom: 6px;
  text-transform: uppercase;
}

.schedule-day.is-today .schedule-day-title {
  color: $color-primary;
  font-weight: bold;
}

.day-plan {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.day-plan-slot + .day-plan-slot {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.day-plan-empty {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 599px) {
  .schedule-day {
    font-size: 0.6875rem;
  }
}

@media screen and (min-width: 600px) {
  .schedule-day {
    font-size: 0.875rem;
  }
}
</style>
